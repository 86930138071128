@use "../utils/variables";

.handicap_review_details_main_card {
  width: var(--width, 832px);
  height: var(--height, 100px);
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  box-shadow: 0px 4px 12px 0px #0000000F;
  padding: 10px;
}

.invisible_card {
  padding: 0px !important;
  border: 1px solid transparent;
  box-shadow: none !important;
}

.small_card {
  width: var(--width, fit-content);
  height: var(--height, fit-content);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  background: #EAECF0;
  border-radius: 4px;
  text-align: center;
}

.golfer_name {
  font-family: National;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: left;
}

.golfer_card_title {
  font-family: National;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
}

.golfer_card_details {
  font-family: National;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: #333333
}

.ghin_number_title {
  font-family: National;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
}

.ghin_number_details {
  font-family: National;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
}

.recommended_adjustment_title {
  font-family: National;
  font-size: 21px;
  font-weight: 800;
  line-height: 25.2px;
  text-align: left;
  color: black;
}

.recommended_adjustment_subtitle {
  font-family: National;
  font-size: 21px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: left;
  color: #333333;
}

.recommended_adjustment_subtitle_pending {
  font-family: National;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #535353;
}


.details_date {
  font-family: National;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #535353;
}

.scores_in_review_title {
  font-family: National;
  font-size: 21px;
  font-weight: 800;
  line-height: 25.2px;
  text-align: center;
}

.scores_in_review_date {
  font-family: National;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #535353;
}

.main_card_title {
  font-family: National;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #535353;
}

.main_card_section_title {
  font-family: National;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
}

.small_card_number {
  font-family: National;
  font-size: 18px;
  font-weight: 800;
  line-height: 21.6px;
  text-align: center;
}

.small_card_details {
  font-family: National;
  font-size: 12px;
  font-weight: 800;
  line-height: 14.4px;
  text-align: center;
  color: #645E5E;
}

.tooltip_text {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: white;
}

.review_details_table_text {
  font-family: National;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: center;
}

.handicap_review_details {
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background-color: variables.$white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  margin: 0.5rem;
  padding: 0.5rem 1rem;
}

.handicap_review_modal_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.handicap_review_modal_title_item {
  font-size: x-large;
  color: variables.$white;
  margin-right: 1rem;
}

.vertical_divider {
  border-left:1px solid #38546d; 
  border-right:1px solid #16222c; 
  height:80px;
  right:249px;
  top:10px; 
}

.bubble{
  border-radius: 6px;
  padding: 0.1rem;
  min-width: 3.2rem;
  max-height: 29px;
  width: fit-content;
  font-size: 12px;
}

.percent_bubble {
  color: rgb(94, 140, 170);
  background-color: rgb(233, 235, 245);
}

.low_bubble {
  color: white;
  background-color: rgb(19, 54, 92);
}

.high_bubble {
  color: white;
  background-color: rgb(56, 137, 203);
}

@mixin applied_colors {
  color: rgba(0, 134, 201);
  background-color:rgba(240, 249, 255);
}

@mixin denied_colors {
  color: rgba(217, 45, 32);
  background-color: rgba(254, 228, 226);
}

@mixin info_colors {
  color: rgb(72,165,221);
  background-color: rgb(236,248,255);
}

@mixin pending_colors {
  color: rgba(220, 104, 3);
  background-color: rgba(254, 240, 199);
}

@mixin zero_colors {
  color: rgba(3, 152, 85);
  background-color: rgba(209, 250, 223);
}

@mixin ineligible_colors {
  color: rgba(83, 83, 83);
  background-color: rgba(234, 236, 240);
}

@mixin expired_colors {
  color: rgba(217, 45, 32);
  background-color: rgba(254, 228, 226);
}

@mixin recommended_adjustment_symbol {
  border-radius: 50%;
  margin-right: 1rem;
  font-size: 1.7rem;
  padding: 0.3rem !important;
}

@mixin review-status-panel {
  border-radius: 1.5rem;
  padding: 0 0.6rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recommended_adjustment_symbol-pending {
  @include pending_colors;
  @include recommended_adjustment_symbol;
}

.recommended_adjustment_symbol-applied {
  @include info_colors;
  @include recommended_adjustment_symbol;
}

.recommended_adjustment_symbol-denied {
  @include denied_colors;
  @include recommended_adjustment_symbol;
}

.recommended_adjustment_symbol-ineligible {
  @include ineligible_colors;
  @include recommended_adjustment_symbol;
}

.recommended_adjustment_symbol-zero{
  @include zero_colors;
  @include recommended_adjustment_symbol;
}

.recommended_adjustment_symbol-expired{
  @include expired_colors;
  @include recommended_adjustment_symbol;
}

.handicap_review_status_panel-applied {
  @include review-status-panel;
  @include applied_colors;
}

.handicap_review_status_panel-denied {
  @include review-status-panel;
  @include denied_colors;
}

.status_point {
  font-size: 3rem;
  padding: none;
  padding-bottom: 0.25rem;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0.75rem;
}

.details_panel {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 0.2rem;
}

.deny_button {
  color: variables.$light_green;
  background-color: variables.$white;
  border: solid variables.$light_green !important;
}

.text_percent {
  font-weight: 700;
}

.text_value {
  color: rgb(0, 40, 81);
  font-size: 2rem;
  font-weight: 700;
}

.text_rounds {
  color: rgb(0, 40, 81);
  font-weight: 700;
}

.text_title {
  color: black;
  font-size: 18px;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
}

.padding_small {
  padding: 0.1rem 0 !important;
  margin: 0.1rem 0 !important;
}

.text_subtitle{
  color: gray;
  font-size: x-large;
}

.chart_cards {
  padding: 0.8rem 0;
}

.text_info {
  color: gray;
  font-size: large;
  line-height: 13px;
}

@mixin bullet {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  margin-right: 0.2rem;
}

.bullet_low {
  @include bullet;
  background-color: rgb(19, 54, 92);;
}

.bullet_high {
  @include bullet;
  background-color: rgb(56, 137, 203);
}

.text_field {
  color: rgb(100, 100, 100);
  font-size: 1.3rem;
}

.text_note {
  font-size: 14px;
  font-style: italic;
  margin-top: 0.7rem !important;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header_cell {
  color: variables.$white;
  background-color: variables.$dark_midnight_blue;
  font-weight: variables.$bold;
  font-size: 14px;
  height: 2.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.sub_header_layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.sub_header_cell {
  background-color: rgb(247, 247, 247);
  font-size: 12px;
  width: 100%;
  height: 2.5rem;
  border: solid 1px variables.$light_gray;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.simple_cells_layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.simple_cell {
  background-color: white;
  width: 100%;
  height: 2.5rem;
  border: solid 1px variables.$light_gray;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
